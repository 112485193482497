import React, { useEffect, useContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, MessageContext } from './core'
import { useLocation } from 'react-router-dom'
import { DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan } from '../partials'
import { MenuComponent } from '../assets/ts/components'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  
  const [messageContent, setmessageContent] = useState({ show: false, type: 'success', message: 'Success', breadCrumb: [] });
  useEffect(() => {
    setmessageContent({ ...messageContent, breadCrumb: [] })
  }, [location])

  const [open, setOpen] = React.useState(true)
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setmessageContent({ ...messageContent, show: false });
  };

  const value = {
    messageContent,
    setmessageContent,
  }

  return (
    <PageDataProvider>
      <MessageContext.Provider value={value}>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <RightToolbar />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <Main />
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
        {/* {JSON.stringify(messageContent)} */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={messageContent.show} autoHideDuration={3000} onClose={handleClose}>
          {(messageContent.type === 'success') ? (<Alert onClose={handleClose} severity="success" sx={{ width: '100%', fontFamily: 'inherit', textTransform: 'capitalize' }}>
            {messageContent.message}
          </Alert>) : (<Alert onClose={handleClose} severity="error" sx={{ width: '100%', fontFamily: 'inherit', textTransform: 'capitalize' }}>
            {messageContent.message}
          </Alert>)}
        </Snackbar>
      </MessageContext.Provider>
    </PageDataProvider>
  )
}

export { MasterLayout }
