export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: any, noTo?: any) {
  const current = getCurrentUrl(pathname)

  let typeof_ = typeof (url)
  if (typeof_ == 'string') {
    if (!current || !url) {
      return false
    }

    if (current === url) {
      return true
    }

    if (current.indexOf(url) > -1) {
      return true
    }
  } else if (typeof_ === 'object') {
    if (noTo !== undefined) {
      let resultNoTo = noTo.find((user: string) => current.includes(user));
      if (resultNoTo !== undefined) {
        return false
      }
    }

    let result = url.find((user: string) => current.includes(user));
    if (result !== undefined) {
      return true
    }

    url.some((item: any) => {
      if (current.indexOf(item) > -1) {
        return true
      } else {
        return false
      }
    })
    if (url.some((item: any) => item.includes(current))) {
      return true
    }
    if (current.indexOf(url) > -1) {
      return true
    }
  }
  return false
}
