/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
/* import {KTSVG} from '../../../helpers' */
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import axios from 'axios';
import { listToTree } from '../../../../app/common/common';
const AsideMenuMain: React.FC = () => {
  const intl = useIntl();
  const API_URL = process.env.REACT_APP_API_URL;
  const [Menu, setMenu] = useState([] as any[]);
  const [UserAccess, setUserAccess] = useState({ data: [] as any })
  useEffect(() => {
    axios.get(`${API_URL}admin-user/token`).then(json => setUserAccess({ data: json.data.data[0].fullPermission.split(',') }));
    axios.get(`${API_URL}menu/admin-menu/all/`).then(json => setMenu(listToTree(json.data.data)));
  }, []);
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        key='dashboard-menu'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
        activeLink="/dashboard"
      />
      {
        Menu && Menu.length > 0 && UserAccess.data && UserAccess.data.length > 0 && Menu.map((menuLink1: any, i: any) => (
          <>
            {
            (menuLink1.list.length > 0 && menuLink1.status == 'active' && UserAccess.data.indexOf(menuLink1._id) > -1 ) && (<>
              <AsideMenuItemWithSub to={[menuLink1.menu_link.split(','), `${(menuLink1.menu_link == 'publication/image-gallery' ? '/publication/social-image-gallery' : '/no-disable-menu')}`]} noTo={[`${(menuLink1.menu_link == 'publication' ? '/publication/image-gallery' : '/no-disable-menu')}`, `${(menuLink1.menu_link == 'publication' ? '/publication/social-image-gallery' : '/no-disable-menu')}`]} title={menuLink1.menu_name} fontIcon="bi-archive" icon="/media/icons/duotune/general/gen022.svg" key={menuLink1._id}>
                {
                  menuLink1.list && menuLink1.list.length > 0 && menuLink1.list.map((menuLink2: any, i: any) => (
                    <>
                      {(menuLink2.list.length > 0 && menuLink2.status == 'active' && UserAccess.data.indexOf(menuLink2._id) > -1) && (<>
                        <AsideMenuItemWithSub to={[`/${menuLink2.menu_link}`]} title={menuLink2.menu_name} fontIcon="bi-archive" icon="/media/icons/duotune/general/gen022.svg" key={menuLink2._id}>
                          {
                            menuLink2.list && menuLink2.list.length > 0 && menuLink2.list.map((menuLink3: any, i: any) => (
                              <>
                                {(menuLink3.status == 'active' && UserAccess.data.indexOf(menuLink3._id) > -1) && (<>
                                  <AsideMenuItem to={`/${menuLink3.menu_link}/list`} title={menuLink3.menu_name} activeLink={`${menuLink3.menu_link}/`} hasBullet={true} key={menuLink3._id} />
                                </>)}
                              </>
                            ))
                          }
                        </AsideMenuItemWithSub>
                      </>)}

                      {(menuLink2.list.length <= 0 && menuLink2.status == 'active' && UserAccess.data.indexOf(menuLink2._id) > -1) && (<>
                        <AsideMenuItem to={`/${menuLink2.menu_link}/list`} title={menuLink2.menu_name} activeLink={`${menuLink2.menu_link}/`} hasBullet={true} key={menuLink2._id} />
                      </>)}
                    </>
                  ))
                }
              </AsideMenuItemWithSub>
            </>)}

            {(menuLink1.list.length <= 0 && menuLink1.status == 'active' && UserAccess.data.indexOf(menuLink1._id) > -1) && (<>
              <AsideMenuItem to={`/${menuLink1.menu_link}/list`} title={menuLink1.menu_name} activeLink={`${menuLink1.menu_link}/`} hasBullet={true} key={menuLink1._id} />
            </>)}
          </>
        ))
      }
      <div className='menu-item' key={`menu-separator`}>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}

export { AsideMenuMain }