import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
const PrivateRoutes = () => {

  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const GlobalCURD = lazy(() => import('../modules/global-curd/global-curd'))
  const NewsCURD = lazy(() => import('../modules/news-curd/news-curd'))
  const LiveBroadcastCURD = lazy(() => import('../modules/broadcast-curd/broadcast-curd'))
  const EventCURD = lazy(() => import('../modules/event-curd/event-curd'))
  const PublicationCURD = lazy(() => import('../modules/publication-curd/publication-curd'))
  const DownloadCURD = lazy(() => import('../modules/download-curd/download-curd'))
  const MandirCURD = lazy(() => import('../modules/mandir-curd/mandir-curd'))
  const CMSCURD = lazy(() => import('../modules/cms-curd/cms-curd'))
  const GeneralCURD = lazy(() => import('../modules/general/general-curd'))
  const Swamibapacc = lazy(() => import('../modules/swamibapacc/cc-curd'))
  const FormCURD = lazy(() => import('../modules/form-curd/form-curd'))
  const PipeBandCURD = lazy(() => import('../modules/pipeband-curd/pipeband-curd'))
  const Menu = lazy(() => import('../modules/menu/menu-curd'))
  const User = lazy(() => import('../modules/user/curd'))
  const Quiz = lazy(() => import('../modules/quiz-curd/quiz-curd'))
  const DonationManageCURD = lazy(() => import('../modules/donation/donation-manage-curd'))

  return (
    <Routes >
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        <Route path='global/*'
          element={
            <SuspensedView>
              <GlobalCURD />
            </SuspensedView>
          }
        />

        <Route
          path='menu/*'
          element={
            <SuspensedView>
              <Menu />
            </SuspensedView>
          }
        />
        <Route
          path='livebroadcast/*'
          element={
            <SuspensedView>
              <LiveBroadcastCURD />
            </SuspensedView>
          }
        />
        <Route
          path='news/*'
          element={
            <SuspensedView>
              <NewsCURD />
            </SuspensedView>
          }
        />

        <Route path='event/*'
          element={
            <SuspensedView>
              <EventCURD />
            </SuspensedView>
          }
        />

        <Route path='publication/*'
          element={
            <SuspensedView>
              <PublicationCURD />
            </SuspensedView>
          }
        />

        <Route path='download/*'
          element={
            <SuspensedView>
              <DownloadCURD />
            </SuspensedView>
          }
        />

        <Route path='donations/*'
          element={
            <SuspensedView>
              <DonationManageCURD />
            </SuspensedView>
          }
        />

        <Route path='mandir/*'
          element={
            <SuspensedView>
              <MandirCURD />
            </SuspensedView>
          }
        />

        <Route path='cms/*'
          element={
            <SuspensedView>
              <CMSCURD />
            </SuspensedView>
          }
        />
        <Route path='general/*'
          element={
            <SuspensedView>
              <GeneralCURD />
            </SuspensedView>
          }
        />
        <Route path='swamibapacc/*'
          element={
            <SuspensedView>
              <Swamibapacc />
            </SuspensedView>
          }
        />
        <Route path='form/*'
          element={
            <SuspensedView>
              <FormCURD />
            </SuspensedView>
          }
        />
        <Route path='pipeband/*'
          element={
            <SuspensedView>
              <PipeBandCURD />
            </SuspensedView>
          }
        />
        <Route path='user/*'
          element={
            <SuspensedView>
              <User />
            </SuspensedView>
          }
        />
        <Route path='quiz/*'
          element={
            <SuspensedView>
              <Quiz />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
